<template>
  <div>
    <v-container fluid class="my-3">
      <!--Header-->
      <v-row class="mb-3">
        <v-col>
          <h2>Customer Details</h2>
        </v-col>
      </v-row>
      <!--Body-->
      <v-row v-if="customer_type === 'customer_individual'">
        <v-container>
          <v-row v-for="item in individualItems" :key="item.name">
            <v-col lg="2"> {{ item.name }}: </v-col>
            <v-col lg="6">{{ item.value ? item.value : "-" }}</v-col>
          </v-row>
        </v-container>
      </v-row>
      <!--Company-->
      <v-row v-if="customer_type === 'customer_company'">
        <v-container>
          <v-row v-for="item in companyItems" :key="item.name">
            <v-col lg="2"> {{ item.name }}: </v-col>
            <v-col lg="6">{{ item.value ? item.value : "-" }}</v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
export default {
  name: "CustomerDetails",
  props: ["customer_id"],
  data() {
    return {
      customer_name: null,
      customer_type: null,
    };
  },
  computed: {
    individualItems() {
      return [
        { name: "Name", value: this.customer_name },
        { name: "Phone", value: this.customer_cell },
        { name: "Email", value: this.customer_email },
        { name: "Address Line 1", value: this.customer_addr_1 },
        { name: "Address Line 2", value: this.customer_addr_2 },
        { name: "City", value: this.customer_city },
        { name: "Zip", value: this.customer_zip },
      ];
    },
    companyItems() {
      return [
        { name: "Name", value: this.customer_name },
        { name: "VAT #", value: this.customer_VAT_number },
        { name: "Reg #", value: this.customer_registration_number },
        { name: "Point of Contact", value: this.customer_contact_person_name },
        { name: "Phone", value: this.customer_contact_person_cell },
        { name: "Email", value: this.customer_contact_person_emaill },
        { name: "Address Line 1", value: this.customer_addr_1 },
        { name: "Address Line 2", value: this.customer_addr_2 },
        { name: "City", value: this.customer_city },
        { name: "Zip", value: this.customer_zip },
      ];
    },
  },
  created() {
    this.getCustomerDetails();
  },
  methods: {
    getCustomerDetails() {
      const docRef = db.doc(`customers/${this.customer_id}`);
      docRef.get().then((doc) => {
        this.customer_name = doc.data().customer_name;
        this.customer_type = doc.data().customer_type;
        this.customer_cell = doc.data().customer_cell;
        this.customer_email = doc.data().customer_email;
        this.customer_addr_1 = doc.data().customer_addr_1;
        this.customer_addr_2 = doc.data().customer_addr_2;
        this.customer_city = doc.data().customer_city;
        this.customer_zip = doc.data().customer_zip;
        this.customer_contact_person_name =
          doc.data().customer_contact_person_name;
        this.customer_contact_person_cell =
          doc.data().customer_contact_person_cell;
        this.customer_contact_person_email =
          doc.data().customer_contact_person_email;
        this.customer_VAT_number = doc.data().customer_VAT_number;
        this.customer_registration_number =
          doc.data().customer_registration_number;
      });
    },
  },
};
</script>